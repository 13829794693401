import { isBoolean } from 'lodash';
import { Fragment } from 'react';
export function BooleanDisplay(props) {
    if (!isBoolean(props.value)) {
        console.warn(`預期 runtime:props.value 是 typing:boolean 但收到 typing:${typeof props.value}`, {
            props,
            'props.value': props.value,
        });
        return <Fragment />;
    }
    return <Fragment>{props.value === true ? 'o' : 'x'}</Fragment>;
}
