import { css } from '@emotion/react';
import { useProxy } from 'valtio/utils';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
import { StrategyOverviewControlBar } from '~/pages/strategy_center_admin/strategy-overview/_components/StrategyOverviewControlBar';
import { StrategyOverviewDataGrid } from '~/pages/strategy_center_admin/strategy-overview/_components/StrategyOverviewDataGrid';
import { StrategyOverviewFilter } from '~/pages/strategy_center_admin/strategy-overview/_components/StrategyOverviewFilter';
import { useMount, useUpdateEffect } from 'react-use';
export const Col1 = () => {
    const state = useProxy(pageStore.strategyOverviewStore);
    useMount(() => {
        pageStore.strategyOverviewStore.loadData();
    });
    useUpdateEffect(() => {
        pageStore.strategyOverviewStore.pagination.setPagination({ page: 1 });
        pageStore.strategyOverviewStore.loadData();
    }, [state.filter]);
    useUpdateEffect(() => {
        pageStore.strategyOverviewStore.loadData();
    }, [state.sorter, state.pagination]);
    return (<div css={css `
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 160px 48px calc(100vh - 248px);
        font-size: 14px;
        box-sizing: border-box;
      `}>
      <StrategyOverviewFilter />
      <StrategyOverviewControlBar />
      <StrategyOverviewDataGrid />
    </div>);
};
