import { css } from '@emotion/react';
import { Button, Chip } from '@mantine/core';
import { without } from 'lodash';
import { useSnapshot } from 'valtio';
import { apirc } from '~/configs/apirc';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
export const InstrumentsFilter = (props) => {
    const instruments = apirc.strategy.http('get', '/api/instruments').useSWR({});
    const selectedItems = useSnapshot(pageStore.strategyOverviewStore.filter).instruments;
    return (<div css={css `
        ${fill_horizontal_cross_center};
        gap: 4px;
      `}>
      <span>策略商品</span>
      <Button onClick={event => {
            props.onChange((instruments.data || []).map(datum => datum.symbolRoot));
        }}>
        全選
      </Button>
      {instruments.data
            ?.filter(instrument => instrument.enableDisplay)
            .map(instrument => {
            const checked = selectedItems.includes(instrument.symbolRoot);
            return (<Chip key={instrument.symbolRoot} checked={checked} onClick={event => {
                    if (checked) {
                        props.onChange(without(selectedItems, instrument.symbolRoot));
                    }
                    else {
                        props.onChange([...selectedItems, instrument.symbolRoot]);
                    }
                }}>
              {instrument.symbolRoot} {instrument.displayName}
            </Chip>);
        })}
    </div>);
};
