import { Select } from '@mantine/core';
import { useProxy } from 'valtio/utils';
import { getSelectProps } from '~/pages/strategy_center_admin/_utils/getSelectProps';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
export function UserAccountSelect(props) {
    const strategists = useProxy(pageStore.strategistsStore).strategists;
    const data = strategists
        .filter(datum => {
        return props.filterByRoles?.includes(datum.role) ?? true;
    })
        .map(datum => {
        return {
            value: datum.account,
            label: datum.account,
        };
    });
    return (<Select label={props.label || '帳號'} searchable hoverOnSearchChange {...getSelectProps({
        data: props.clearable ? [{ value: '', label: '無' }, ...data] : [...data],
        value: props.value || '',
        onChange(datum) {
            props.onChange(datum.value);
        },
    })}/>);
}
