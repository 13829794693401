import { css } from '@emotion/react';
import { Button, Chip } from '@mantine/core';
import { without } from 'lodash';
import { useSnapshot } from 'valtio';
import { apirc } from '~/configs/apirc';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
export const TagsFilter = (props) => {
    const tags = apirc.strategy.http('get', '/api/strategies/tags').useSWR({});
    const selectedItems = useSnapshot(pageStore.strategyOverviewStore.filter).tags;
    return (<div css={css `
        ${fill_horizontal_cross_center};
        gap: 4px;
      `}>
      <span>標籤</span>
      <Button onClick={event => {
            props.onChange(tags.data?.map(tag => tag.tagName) || []);
        }}>
        全選
      </Button>
      {tags.data?.map(tag => {
            const checked = selectedItems.includes(tag.tagName);
            return (<Chip key={tag.tagName} checked={checked} onClick={event => {
                    if (checked) {
                        props.onChange(without(selectedItems, tag.tagName));
                    }
                    else {
                        props.onChange([...selectedItems, tag.tagName]);
                    }
                }}>
            {tag.tagName}
          </Chip>);
        })}
    </div>);
};
